import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',

    [theme.breakpoints.up('lg')]: {
      
      transitionDuration: 500
      //paddingRight: 70
    }
  },
  wrapper1: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    transitionDuration: 500,

    [theme.breakpoints.up('lg')]: {
      //paddingLeft: 70,
      transitionDuration: 500
    }
  },
  mobile: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 0,
    transitionDuration: 500,

    [theme.breakpoints.up('lg')]: {
      //paddingLeft: 70,
      transitionDuration: 500
    }
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const theme1 = useTheme();
  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));

  return (
    <div className={classes.root}>
    
      {mobileDevice ? (
        <>
          <div className={classes.mobile}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>{children}</div>
            </div>
          </div>
        </>
      ) : (
        <div className={open ? classes.wrapper : classes.wrapper1}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
