import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import NoMenuLayout from 'src/layouts/NoMenuLayout';
import NoLayout from 'src/layouts/NoLayout';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import Loader from 'src/components/LoadingScreen/LoadingScreen';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader open={true} />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
  },
  {
    exact: true,
    path: '/privacy_policy',
    component: lazy(() => import('src/views/auth/LoginView/PrivacyPolicy'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/apply/:admission_id',
    component: lazy(() => import('src/views/auth/LoginView/Apply'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signUp',
    component: lazy(() => import('src/views/auth/LoginView/SignUp'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,

    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() =>
      import('src/views/auth/LoginView/Confirm_forgot_password')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirmSignUp',
    component: lazy(() => import('src/views/auth/LoginView/confirmSignUp'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirmSignUpp',
    component: lazy(() =>
      import('src/views/auth/LoginView/confirmSignUpafterLogin')
    )
  },
  {
    path: '/app/admin/test/quiz',
    guard: AuthGuard,
    layout: NoLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin/test/quiz/:exam_reg_id/:exam_id',
        component: lazy(() => import('src/views/Tasks/Test'))
      }
    ]
  },
  {
    path: '/app/admin/academics/assignment',
    guard: AuthGuard,
    layout: NoMenuLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin/academics/assignment/:course/:task_id',
        component: lazy(() =>
          import('src/views/Academics/Sem1/OnlineAssignment')
        )
      }
    ]
  },
  {
    path: '/app/admin/test/online',
    guard: AuthGuard,
    layout: NoMenuLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin/test/online/dashboard/:exam_id',
        component: lazy(() => import('src/views/Tasks/Dashboard'))
      },

      {
        exact: true,
        path: '/app/admin/test/online/answer/:exam_reg_id/:exam_id',
        component: lazy(() => import('src/views/Tasks/ShowAnswers'))
      }
    ]
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin/idcard',
        component: lazy(() => import('src/views/IDCard'))
      },
      {
        exact: true,
        path: '/app/admin/calendar',
        component: lazy(() => import('src/views/Calendar'))
      },
      {
        exact: true,
        path: '/app/admin/profile/TechnicalSkills',
        component: lazy(() => import('src/views/TechnicalSkillsEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Internships',
        component: lazy(() => import('src/views/InternshipsEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Projects',
        component: lazy(() => import('src/views/ProjectsEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Accomplishments',
        component: lazy(() => import('src/views/AccomplishmentsEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Social',
        component: lazy(() => import('src/views/StudentProfile/SocialEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Language',
        component: lazy(() => import('src/views/StudentProfile/LanguageEdit'))
      },
      {
        exact: true,
        path: '/app/admin/profile',
        component: lazy(() => import('src/views/StudentProfile'))
      },
      {
        exact: true,
        path: '/app/admin/certificates',
        component: lazy(() => import('src/views/Certificates'))
      },
      {
        exact: true,
        path: '/app/admin/documents',
        component: lazy(() => import('src/views/Documents'))
      },
      {
        exact: true,
        path: '/app/admin/academics',
        component: lazy(() => import('src/views/Academics/Sem1'))
      },
      {
        exact: true,
        path: '/app/admin/courses',
        component: lazy(() => import('src/views/Courses'))
      },
      //
      {
        exact: true,
        path: '/app/admin/courseSearch',
        component: lazy(() => import('src/views/SearchCourses/SearchCourse'))
      },
      {
        exact: true,
        path: '/app/admin/courses/:course',
        component: lazy(() => import('src/views/Courses/CourseView'))
      },
      //
      {
        exact: true,
        path: '/app/admin/more',
        component: lazy(() => import('src/views/More'))
      },
      {
        exact: true,
        path: '/app/admin/profile/Experience',
        component: lazy(() => import('src/views/ExperienceEdit'))
      },
      {
        exact: true,
        path: '/app/admin/academics/:course/:term',
        component: lazy(() =>
          import('src/views/Academics/Sem1/CourseDetailMobile')
        )
      },

      {
        exact: true,
        path: '/app/admin/academics/lab/:course_code',
        component: lazy(() => import('src/views/Academics/Sem1/LabDetails'))
      },
      {
        exact: true,
        path: '/app/admin/payments',
        component: lazy(() => import('src/views/Payments'))
      },

      {
        exact: true,
        path: '/app/admin/resume',
        component: lazy(() => import('src/views/Resume'))
      },
      {
        exact: true,
        path: '/app/admin/test/list',
        component: lazy(() => import('src/views/Tasks'))
      },

      {
        exact: true,
        path: '/app/admin/feedback',
        component: lazy(() => import('src/views/FeedBack'))
      },
      
      {
        exact: true,
        path: '/app/admin/feedback/:id',
        component: lazy(() => import('src/views/FeedBack/Results'))
      },
      {
        exact: true,
        path: '/app/admin/feedback/:id/:course_code/form/:feedback_obj_id',
        component: lazy(() => import('src/views/FeedBack/FeedBackForm'))
      },
      {
        exact: true,
        path: '/app/admin/institution',
        component: lazy(() => import('src/views/Institution'))
      },
      {
        exact: true,
        path: '/app/fee/order/:id/success',
        component: lazy(() =>
          import('src/views/Payments/StudentsFee/OrderDetailSuccess')
        )
      },
      {
        exact: true,
        path: '/app/fee/order/:id/failure',
        component: lazy(() =>
          import('src/views/Payments/StudentsFee/OrderDetailFailure')
        )
      },
      // {
      //   component: () => <Redirect to="/404" />
      // }
    ]
  },

  // {
  //   path: '/app',
  //   guard: AuthGuard,
  //   layout: AllApps,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/admin/applications/detail',

  //       component: lazy(() => import('src/views/Applications/AppDetails'))
  //     },
  //   ]
  // },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
