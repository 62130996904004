/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  List,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';

const sections = [
  {
    items: [
      {
        title: 'Home',
        href: '/app/admin/alumnihome',
        icon: HomeOutlinedIcon
      },
      {
        title: 'Profile',
        href: '/app/admin/profile',
        icon: SupervisedUserCircleOutlinedIcon
      },
      {
        title: 'Alumni',
        href: '/app/admin/alumni',
        icon: GroupAddOutlinedIcon
      },
      {
        title: 'Messages',
        href: '/app/admin/alumnimessage',
        icon: MessageOutlinedIcon
      },    
      {
        title: 'More',
        href: '/app/admin/alumnimore',
        icon: FormatListBulletedOutlinedIcon
      }
    ]
  }
];

const students = [
  {
    items: [
      {
        title: 'Profile',
        href: '/app/admin/profile',
        icon: AssignmentIndOutlinedIcon
      },

      {
        title: 'Payments',
        href: '/app/admin/payments',
        icon: SwapVerticalCircleOutlinedIcon
      },
      {
        title: 'Grading',
        href: '/app/admin/academics',
        icon: ListAltIcon
      },
      {
        title: 'Feedback',
        href: '/app/admin/feedback',
        icon: RateReviewOutlinedIcon
      },

      {
        title: 'More',
        href: '/app/admin/more',
        icon: FormatListBulletedOutlinedIcon
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      {items.map(item => {
        const key = item.title + depth;

        if (item.items) {
          const open = matchPath(pathname, {
            path: `${item.href}/*`,
            exact: false
          });

          return (
            <NavItem
              depth={depth}
              icon={item.icon}
              info={item.info}
              key={key}
              open={Boolean(open)}
              title={item.title}
            >
              {renderNavItems({
                depth: depth + 1,
                pathname,
                items: item.items
              })}
            </NavItem>
          );
        } else {
          return (
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
      })}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: '100%',
    height: 55,
    position: 'fixed',
    bottom: 0,
    left: 0
  },
  desktopDrawer: {
    width: '100%',
    height: 60,
    borderTop: 'none',
    position: 'fixed',
    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.06)',
    paddingTop: 0,
    bottom: 0,
    left: 0
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Drawer
        anchor="bottom"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
        style={{ overflowY: 'hidden' }}
      >
        {user.user_group === 'alumni' ? (
          <Box
            // height="100%"
            display="flex"
            style={{ overflowY: 'hidden' }}
            flexGrow={1}
            p={0}
            m={0}
            marginTop={-1}
            paddingBottom={-1}
            justifyContent="center"
            flexDirection="row"
          >
            {sections.map(section => (
              <List
                key={section.subheader}
                style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
                length={sections.length}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
        ) : (
          <Box
            // height="100%"
            display="flex"
            style={{
              overflowY: 'hidden',
            }}
            flexGrow={1}
            p={0}
            m={0}
            marginTop={-1}
            mpaddingBottom={-1}
            justifyContent="center"
            flexDirection="row"
          >
            {students.map(section => (
              <List
                key={section.subheader}
                style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
                length={sections.length}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
        )}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
