import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    // enqueueSnackbar('Login Successful', {
    //   anchorOrigin: {
    //     vertical: 'top',
    //     horizontal: 'center',
    // },
    //   variant: 'success'
    // })
    return <Redirect  to={`${user ? user.user_group === 'admin' ? ("/app/admin/newevents" ) : user.user_group === 'guest' ? ("/app/admin/newevents" ) : ("/app/admin/profile" ) : '/app/admin/profile'}`}/>;

  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
