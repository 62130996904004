import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }) => {

  const { isAuthenticated } = useAuth();
  const {message } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
    
  }
 if (isAuthenticated && message == "UserNotConfirmed") {
    
  return <Redirect to="/confirmSignUpp"/>;
}

if (isAuthenticated && message == "NEW_PASSWORD_REQUIRED") {
    
  return <Redirect to="/reset_password"/>;
}
  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;