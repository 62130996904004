import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  get_students: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {

      const { get_students } = action.payload;
      
      state.notifications = get_students;
    }
  }
});

export const reducer = slice.reducer;

export const getNotifications = () => async (dispatch) => {
  
let response = []

  dispatch(slice.actions.getNotifications(response));
};

export default slice;
